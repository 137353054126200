import React from 'react'
import { Button } from '../ButtonElements'
import { InfoContainer, InfoWrapper, InfoRow, Column1, Column2, TextWrapper, TopLine, Heading, Subtitle, BtnWrap, ImgWrap, Img, Offerss } from './InfoElements'
import rideImage from '../../images/Ride1.png'
import zigzag from '../../images/zig zag.png'

import offer1 from '../../images/offer4.jpg'
import offer2 from '../../images/offer5.jpg'
import offer3 from '../../images/offer6.jpg'
// import Offerss from './InfoElements';
import { useTranslation } from 'react-i18next';



const Offers = ({lightBg, id, imgStart, topLine, lightText, headline, darkText, description, buttonLabel, alt, img, primary, dark, dark2 }) => {
  const { t } = useTranslation();
return (
    <>
      <InfoContainer lightBg={false} id='offers'>
        <InfoWrapper>
          <InfoRow imgStart={false}>
          <img alt="little" src={zigzag}/>
              <TextWrapper style={{margin:'0 auto',textAlign:'center'}}>
             
             <Heading lightText={false} style={{fontWeight:'bold',fontSize:'30px'}}>
    {t('offers.heading')}
  </Heading>
                
                
                <Subtitle darkText={true}>
    {t('offers.description')}
  </Subtitle>
                <span>
                  
                </span>
              </TextWrapper>
            
          </InfoRow>
          <Offerss> 
          <div class="flip-card">
              <div class="flip-card-inner">
                <div class="flip-card-front">
                  <img src={offer1} alt="Avatar" style={{width:'300px',height:'300px'}}/>
                </div>
                <div class="flip-card-back">
                  {/* <h1>Get this offer</h1>  */}
                  <Button to='home'
                  smooth={true}
                  duration={500}
                  onClick={()=> window.open("https://little.africa/littlerider", "_blank")}
                  spy={true}
                  exact='true'
                  offset={-80}
                  primary={primary ? 1 : 0}
                  dark={dark ? 1 : 0}
                  dark2={dark2 ? 1 : 0}
                  >
                   {t('offers.getApp')}
                  </Button>
                </div>
              </div>
              </div>

              <div class="flip-card">
              <div class="flip-card-inner">
                <div class="flip-card-front">
                  <img src={offer2} alt="Avatar" style={{width:'300px',height:'300px'}}/>
                </div>
                <div class="flip-card-back">
                  {/* <h1>Get this offer</h1>  */}
                  <Button to='home'
                  smooth={true}
                  duration={500}
                  onClick={()=> window.open("https://little.africa/littlerider", "_blank")}
                  spy={true}
                  exact='true'
                  offset={-80}
                  primary={primary ? 1 : 0}
                  dark={dark ? 1 : 0}
                  dark2={dark2 ? 1 : 0}
                  >
                    {t('offers.getApp')}     
                  </Button>
                </div>
              </div>
              </div>
              <div class="flip-card">
              <div class="flip-card-inner">
                <div class="flip-card-front">
                  <img src={offer3} alt="Avatar" style={{width:'300px',height:'300px'}}/>
                </div>
                <div class="flip-card-back">
                  {/* <h1>Get this offer</h1>  */}
                  <Button to='home'
                  smooth={true}
                  duration={500}
                  onClick={()=> window.open("https://little.africa/littlerider", "_blank")}
                  spy={true}
                  exact='true'
                  offset={-80}
                  primary={primary ? 1 : 0}
                  dark={dark ? 1 : 0}
                  dark2={dark2 ? 1 : 0}
                  >
                    {t('offers.getApp')}
                  </Button>
                </div>
              </div>
              </div>
          </Offerss>

        </InfoWrapper>
      </InfoContainer>
    </>
  )
}

export default Offers
