import './App.css';
import { Switch, Route } from 'react-router-dom';
import Home from './pages';
import SigninPage from './pages/signin';
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { isAndroid, isDesktop, isIOS } from 'react-device-detect'
import { DotLoader } from 'react-spinners';

import { LanguageProvider } from './contexts/LanguageContext';

function App() {
  return (
    <LanguageProvider>
      <Switch>

       <Route path='/littledriver' component={() => { 
            if(isAndroid) {
              window.location.href = 'https://play.google.com/store/apps/details?id=com.craftsilicon.littlecabdriver'; 
            }
            if(isIOS) {
              window.location.href = 'https://apps.apple.com/ke/app/little-agent/id1547421613'; 
            }
            if(isDesktop) {
              window.location.href = 'https://play.google.com/store/apps/details?id=com.craftsilicon.littlecabdriver'; 
            }
            return <div style={{marginTop: '170px' ,width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center',  textAlign:"center", flexDirection: "column"}}>
              <DotLoader color={"#000000"} loading={true}  size={50} /><br/><br/>
              <span style={{fontFamily: 'Helvetica, sans-serif' , color: '#353232', width: '50%', lineHeight:  '1.5', fontSize: '20px', fontWeight: '700'}}>
              <span style={{fontFamily: 'Helvetica, sans-serif' ,color: '#353232', fontSize: '20px', textAlign:"center"}}>your</span><br/>
              <span style={{fontFamily: 'Helvetica, sans-serif' ,color: '#00ba75', fontSize: '20px'}}>kila</span>
              <span style={{fontFamily: 'Helvetica, sans-serif' ,color: '#008ad0', fontSize: '20px'}}>day</span>.
              <span style={{fontFamily: 'Helvetica, sans-serif' ,color: '#FF0000', fontSize: '20px'}}>every</span>
              <span style={{fontFamily: 'Helvetica, sans-serif' ,color: '#FF0000', fontSize: '20px'}}>thing</span>.
              app!<br/><br/>
              </span>
            </div>
        }} />


        <Route path='/littlerider' component={() => { 
            if(isAndroid) {
              window.location.href = 'https://play.google.com/store/apps/details?id=com.craftsilicon.littlecabrider'; 
            }
            if(isIOS) {
              window.location.href = 'https://apps.apple.com/ke/app/little-ride/id1130691846'; 
            }
            if(isDesktop) {
              window.location.href = 'https://play.google.com/store/apps/details?id=com.craftsilicon.littlecabrider'; 
            }
            return <div style={{marginTop: '170px' ,width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center',  textAlign:"center", flexDirection: "column"}}>
              <DotLoader color={"#000000"} loading={true}  size={50} /><br/><br/>
              <span style={{fontFamily: 'Helvetica, sans-serif' , color: '#353232', width: '50%', lineHeight:  '1.5', fontSize: '20px', fontWeight: '700'}}>
              <span style={{fontFamily: 'Helvetica, sans-serif' ,color: '#353232', fontSize: '20px', textAlign:"center"}}>your</span><br/>
              <span style={{fontFamily: 'Helvetica, sans-serif' ,color: '#00ba75', fontSize: '20px'}}>kila</span>
              <span style={{fontFamily: 'Helvetica, sans-serif' ,color: '#008ad0', fontSize: '20px'}}>day</span>.
              <span style={{fontFamily: 'Helvetica, sans-serif' ,color: '#FF0000', fontSize: '20px'}}>every</span>
              <span style={{fontFamily: 'Helvetica, sans-serif' ,color: '#FF0000', fontSize: '20px'}}>thing</span>.
              app!<br/><br/>
              </span>
            </div>
        }} />

        <Route exact path="/" component={Home} />
        <Route path='/contactUs' component={<SigninPage />} />
        <Route path='/little' component={() => { 
            window.location.href = 'https://little.bz/app/'; 
            return <div>Loading...</div>;
        }} />

        <Route path='/vercel' component={() => {   
            window.location.href = 'https://littleafrica.vercel.app/app';    
            return <div>Loading...</div>;
        }} />  

        <Route path='/driver' component={() => { 
            if(isAndroid) {
              window.location.href = 'https://play.google.com/store/apps/details?id=com.craftsilicon.littlecabdriver'; 
            }
            if(isIOS) {
              window.location.href = 'https://apps.apple.com/ke/app/little-agent/id1547421613'; 
            }
            if(isDesktop) {
              window.location.href = 'https://play.google.com/store/apps/details?id=com.craftsilicon.littlecabdriver'; 
            }
            return <div style={{marginTop: '170px' ,width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center',  textAlign:"center", flexDirection: "column"}}>
              <DotLoader color={"#000000"} loading={true}  size={50} /><br/><br/>
              <span style={{fontFamily: 'Helvetica, sans-serif' , color: '#353232', width: '50%', lineHeight:  '1.5', fontSize: '20px', fontWeight: '700'}}>
              <span style={{fontFamily: 'Helvetica, sans-serif' ,color: '#353232', fontSize: '20px', textAlign:"center"}}>your</span><br/>
              <span style={{fontFamily: 'Helvetica, sans-serif' ,color: '#00ba75', fontSize: '20px'}}>kila</span>
              <span style={{fontFamily: 'Helvetica, sans-serif' ,color: '#008ad0', fontSize: '20px'}}>day</span>.
              <span style={{fontFamily: 'Helvetica, sans-serif' ,color: '#FF0000', fontSize: '20px'}}>every</span>
              <span style={{fontFamily: 'Helvetica, sans-serif' ,color: '#FF0000', fontSize: '20px'}}>thing</span>.
              app!<br/><br/>
              </span>
            </div>
        }} />

        <Route path='/app' component={() => { 
            if(isAndroid) {
              window.location.href = 'https://play.google.com/store/apps/details?id=com.craftsilicon.littlecabrider'; 
            }
            if(isIOS) {
              window.location.href = 'https://apps.apple.com/ke/app/little-ride/id1130691846'; 
            }
            if(isDesktop) {
              window.location.href = 'https://play.google.com/store/apps/details?id=com.craftsilicon.littlecabrider'; 
            }
            return <div style={{marginTop: '170px' ,width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center',  textAlign:"center", flexDirection: "column"}}>
              <DotLoader color={"#000000"} loading={true}  size={50} /><br/><br/>
              <span style={{fontFamily: 'Helvetica, sans-serif' , color: '#353232', width: '50%', lineHeight:  '1.5', fontSize: '20px', fontWeight: '700'}}>
              <span style={{fontFamily: 'Helvetica, sans-serif' ,color: '#353232', fontSize: '20px', textAlign:"center"}}>your</span><br/>
              <span style={{fontFamily: 'Helvetica, sans-serif' ,color: '#00ba75', fontSize: '20px'}}>kila</span>
              <span style={{fontFamily: 'Helvetica, sans-serif' ,color: '#008ad0', fontSize: '20px'}}>day</span>.
              <span style={{fontFamily: 'Helvetica, sans-serif' ,color: '#FF0000', fontSize: '20px'}}>every</span>
              <span style={{fontFamily: 'Helvetica, sans-serif' ,color: '#FF0000', fontSize: '20px'}}>thing</span>.
              app!<br/><br/>
              </span>
            </div>
        }} />

        <Route path='/supplyleads' component={() => { 
            window.location.href = 'https://docs.google.com/forms/d/e/1FAIpQLScM-N1FEFMxxlp7norybdhssle6rbs0tIgj9mEYVZXk-0zdSw/alreadyresponded'; 
            return null;
        }} />

        <Route path="/:country" component={Home} />


      </Switch>
    </LanguageProvider>
  );
}
export default App;
