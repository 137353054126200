import React from 'react'
import { Button } from '../ButtonElements'
import { InfoContainer, InfoWrapper, InfoRow, Column1, Column2, TextWrapper, TopLine, Heading, Subtitle, BtnWrap, ImgWrap, Img } from './InfoElements'
import rideImage from '../../images/Ride1.png'
import zigzag from '../../images/zig zag.png'
import axios from 'axios'
import { useTranslation } from 'react-i18next';

const Subscribe = ({lightBg, id, imgStart, topLine, lightText, headline, darkText, description, buttonLabel, alt, img, primary, dark, dark2 }) => {
  const { t } = useTranslation();
  
  const [message, setMessage] = React.useState('');
  const [error, setError] = React.useState(false);
  const [value, handleChange] = React.useState('');

  const handleClick = () => {
    setError(false)
    setMessage("loading ...")
    const user = {
      email: value
    };

    axios.post(`https://www.little.bz/blog/wp-json/wp/v2/users/register`, { email : value })
      .then(res => {
        setError(false)
        console.log(res);
        console.log("my getting the following data " , res.data);
        setMessage("Thank you for subscribing")

      }).catch(error => {
        setError(true)
        console.log("my error is ", error.response.data.message );
        setMessage(error.response.data.message)
    });
  }

  return (
    <>
      <InfoContainer lightBg={false} id='rise' >
        <InfoWrapper>
          <InfoRow imgStart={false} sx={{border:'1px solid red'}}>
            <TextWrapper>
              <Heading lightText={false} style={{fontSize:'30px'}}>
                {t('subscribe.heading')}
              </Heading>
              <Subtitle darkText={false}>
                {t('subscribe.subtitle')}
              </Subtitle>
            </TextWrapper>
            <div>
              <span style={{color: error ? 'red' : 'green', marginLeft: '20px'}}>{message}</span>
              <div class="containernews" style={{width: '400px',border:'1px solid blue;'}}>
                <input 
                  type="text" 
                  class="text_input"
                  placeholder={t('subscribe.placeholder')}
                  onChange={(e) => handleChange(e.target.value)}
                />
                <button value="Save" class="btn" onClick={() => handleClick()}>
                  {t('subscribe.button')}
                </button>
              </div>
            </div>
          </InfoRow>
        </InfoWrapper>
      </InfoContainer>
    </>
  )
}

export default Subscribe