import React,{useState} from 'react'
import { Button } from '../ButtonElements'
import { InfoContainer, InfoWrapper, InfoRow, Column1, Column2, TextWrapper, TopLine, Heading, Subtitle, BtnWrap, ImgWrap, Img } from './InfoElements'
import zigzag from '../../images/zig zag.png'
import corporateImage from "../../images/corporate.png"
import { Nav, NavbarContainer, NavLogo, MobileIcon, NavMenu, NavLinks, NavItem, NavBtn, NavBtnLink, subMenu, subMenuList } from '../Navbar/NavbarElements'
import { useTranslation } from 'react-i18next';


const Corporate = ({lightBg, id, imgStart, topLine, lightText, headline, darkText, description, buttonLabel, alt, img, primary, dark, dark2 }) => {

  const [isOpen, setOpen] = useState(false)
   const { t } = useTranslation();
  return (
    <>
      <InfoContainer lightBg={lightBg} id='corporate'>
        <InfoWrapper style={{backgroundColor: '#ffffff'}}>
          <InfoRow imgStart={true}>
            <Column1>
              <TextWrapper>
      <span style={{color: '#000000', fontSize: '42px'}}>
        <span style={{fontWeight: '800', textAlign: 'right', color: '#263063', flex: 1}}>
          {t('corporate.heading')}
        </span>
      </span>
      <Subtitle darkText={darkText} style={{textAlign:"left",color:"rgba(0,0,0,.9)",fontSize:'15px'}}>
        {t('corporate.intro')}
        <div style={{display:'flex',justifyContent:'left',alignItems:"center",marginTop:'7px',marginBottom:'8px'}}>
          <ul style={{textAlign:'left',color:"rgba(0,0,0,.8)",marginLeft:"40px"}}>
            <li>{t('corporate.services.taxi')}</li>
            <li>{t('corporate.services.shuttle')}</li>
            <li>{t('corporate.services.logistics')}</li>
            <li>{t('corporate.services.executive')}</li>
          </ul>
        </div>
        <div>{t('corporate.platform')}</div>
        <div style={{marginTop:'10px'}}>{t('corporate.visibility')}</div>
      </Subtitle>
      <NavBtn style={{ display:'flex' ,justifyContent:"right"}}>
        <NavBtnLink onClick={()=> window.open("https://little.africa/corporate", "_blank")}>
          {t('corporate.button')}
        </NavBtnLink>
      </NavBtn>
    </TextWrapper>
            </Column1>
            <Column2 >
            <ImgWrap >
                <Img src={corporateImage}  alt={alt}/>
              </ImgWrap>
            </Column2>
            
          </InfoRow>
        </InfoWrapper>
        
      </InfoContainer>
    </>
  )
}

export default Corporate
