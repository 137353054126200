import React, {useEffect, useState} from 'react'
import Footer from '../components/Footer';
import { homeObjOne, homeObjTwo} from '../components/Info/Data';
import Navbar from '../components/Navbar'
import Sidebar from '../components/SideBar';
import Mobility from '../components/Mobility';
import Ride from './../components/Ride';
import Deliveries from './../components/Deliveries';
import Corporate from './../components/Corporate';
import Entertainment from '../components/Entertainment';
import Blogs from '../components/Blogs';
import Offers from '../components/Offers';
import Subscribe from '../components/Subscribe';
import GlobalFonts from '../fonts/fonts';
import TopSlider from './../components/Hero/InfoSection';
import {sliderData } from './Data';
import Pricing from './../components/Services/Pricing';
import { BrowserView, MobileView} from 'react-device-detect';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import '../i18n';

import { useLocation } from 'react-router-dom';

const Home = () => {
  const [isOpen, setIsOpen] = useState(false)
  const location = useLocation();
  const { i18n } = useTranslation();

  useEffect(() => {
    const getGeoInfo = () => {
      const countryLanguageMap = {
        'ke': 'en',
        'ug': 'en',
        'gh': 'en',
        'tz': 'sw',
        'et': 'am',
        'so': 'so',
        'sn': 'fr',
        'in': 'hi'
      };

      const country = location.pathname.split('/')[1];
      const language = countryLanguageMap[country] || 'en';
      localStorage.setItem('i18nextLng', language);
      i18n.changeLanguage(language);
    };
    
    getGeoInfo();
  }, [i18n, location]);

  const toggle = () =>{
    setIsOpen(!isOpen);
  }

  return (
    <div style={{margin: 0, padding: 0, maxWidth: '100%', overflowX: 'clip'}}>
      <BrowserView>
      <GlobalFonts />
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle}/>
      <TopSlider  {...sliderData}/>
      {/* <Pricing/> */}
      <Mobility {...homeObjTwo}/>
      <Ride {...homeObjOne}/>
      <Deliveries {...homeObjOne}/>
      <Corporate {...homeObjOne}/>
      <Entertainment {...homeObjOne}/>
      <Blogs {...homeObjOne}/>
      <Offers {...homeObjOne}/>
        <Subscribe {...homeObjOne} />
        
      <Footer />
        </BrowserView>
        <MobileView>
        <GlobalFonts />
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle}/>
      <TopSlider  {...sliderData}/>
      <Pricing/>
      <Mobility {...homeObjTwo}/>
      <Ride {...homeObjOne}/>
      <Deliveries {...homeObjOne}/>
      <Corporate {...homeObjOne}/>

      <Entertainment {...homeObjOne}/>
      <Blogs {...homeObjOne} />
      <Offers {...homeObjOne} />
      <Subscribe {...homeObjOne} />

      <Footer />
        </MobileView>
     
    </div>
  )
}
export default Home
