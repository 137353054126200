import React, {useState, useEffect} from 'react'
import { FaBars } from 'react-icons/fa'
import {IconContext} from 'react-icons/lib'
import { Nav, NavbarContainer, NavLogo, MobileIcon, NavMenu, NavLinks, NavItem, NavBtn, NavBtnLink, subMenu, subMenuList, NavBtnLinkSacco } from './NavbarElements'
import { animateScroll as scroll } from 'react-scroll';
import { useTranslation } from 'react-i18next';

const Navbar = ({ toggle }) => {
  const [scrollNav, setScrollNav] = useState(false)
  const changeNav = () => {
    if(window.scrollY >= 80) {
      setScrollNav(true)
    } else {
      setScrollNav(false)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', changeNav)
  }, [])

  const toggleHome = () => {
    scroll.scrollToTop();
  }

  const { t } = useTranslation();

  return (
    <>
    <IconContext.Provider value={{ color: '#fff'}}>
      <Nav scrollNav={scrollNav}>
      
        <NavbarContainer>
        <MobileIcon onClick={toggle}>
            <FaBars style={{color: '#3d4143'}}/>
          </MobileIcon>
          <NavLogo to='/' onClick={toggleHome}></NavLogo>
                              <NavMenu>
                              <NavItem>
                                  <NavLinks to='home'
                                  smooth={true} duration={500} spy={true} exact='true' offset={-80}
                                  >{t('nav.home')}</NavLinks>
                                </NavItem>
                                <NavItem>
                                  <div className="dropdown" style={{marginTop:"10px"}}>
                                    <button className="dropbtn">{t('nav.services')}</button>
                                    <div className="dropdown-content">
                                      <NavLinks 
                                        to='mobility'
                                        smooth={true} 
                                        duration={500} 
                                        spy={true} 
                                        exact='true' 
                                        offset={-80}
                                      >Rides</NavLinks>

                                      <a href="#" onClick={() => window.open('https://little.africa/corporate/', '_blank')}>
                                        Corporate
                                      </a>

                                      <NavLinks 
                                        to='entertainment'
                                        smooth={true} 
                                        duration={500} 
                                        spy={true} 
                                        exact='true' 
                                        offset={-80}
                                      >Events</NavLinks>

                                      <NavLinks 
                                        to='entertainment'
                                        smooth={true} 
                                        duration={500} 
                                        spy={true} 
                                        exact='true' 
                                        offset={-80}
                                      >Movies</NavLinks>

                                      <NavLinks 
                                        to='mobility'
                                        smooth={true} 
                                        duration={500} 
                                        spy={true} 
                                        exact='true' 
                                        offset={-80}
                                      >Emobility</NavLinks>

                                      <NavLinks 
                                        to='delivery'
                                        smooth={true} 
                                        duration={500} 
                                        spy={true} 
                                        exact='true' 
                                        offset={-80}
                                      >Deliveries</NavLinks>

                                      <NavLinks 
                                        to='delivery'
                                        smooth={true} 
                                        duration={500} 
                                        spy={true} 
                                        exact='true' 
                                        offset={-80}
                                      >Logistics</NavLinks>
                                    </div>
                                  </div>
                                </NavItem>
            {/* <NavItem>
              <NavLinks to='services'
              smooth={true} duration={500} spy={true} exact='true' offset={-80}
              >{t('nav.services')}</NavLinks>
            </NavItem> */}
            <NavItem>
              <NavLinks to='offers'
              smooth={true} duration={500} spy={true} exact='true' offset={-80}
              >{t('nav.offers')}</NavLinks>
            </NavItem>
            <NavItem sx={{border:"1px solid red"}}>

                <div className="dropdown" style={{marginTop:"10px"}} >
                  <button className="dropbtn">{t('nav.register')}</button>
                  <div className="dropdown-content">



                    <a href="https://drivers.little.bz/login" target={'_blank'} onClick={() => window.open(
                        'https://drivers.little.bz/login',
                        '_blank' // <- This is what makes it open in a new window.
                      )}>{t('nav.driver')}
                      
                      </a>
                    <a href="https://partners.little.bz/login" target={'_blank'} onClick={() => window.open(
                        'https://partners.little.bz/login',
                        '_blank' // <- This is what makes it open in a new window.
                      )}>{t('nav.partner')}
                      </a>
                  </div>
                </div>

              {/* </NavLinks> */}

            </NavItem>
          </NavMenu>
          <NavBtn>
              <NavBtnLink onClick={()=> window.open("https://little.africa/corporate", "_blank")}>{t('nav.corporate')}</NavBtnLink>

          </NavBtn>
        </NavbarContainer>
        <NavBtn>
            <NavBtnLinkSacco onClick={()=> window.open("https://littlesacco.craftsilicon.com/ib", "_blank")}>{t('nav.sacco')}</NavBtnLinkSacco>
          </NavBtn>
      </Nav>
    </IconContext.Provider>
    </>
  )
}

export default Navbar