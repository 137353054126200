import React from 'react'
import { FaFacebook, FaInstagram, FaLinkedin, FaTwitter, FaYoutube , FaTiktok } from 'react-icons/fa'
import { FooterContainer, FooterWrap, FooterLinkWrapper, FooterLinkItems, FooterLinkContainer, FooterLinkTitle, FooterLink, SocialIconLink, SocialIcons, SocialLogo, SocialMedia, SocialMediaWrap, WebsiteRights, TermsAndConditions } from './FooterElements'
import { animateScroll as scroll } from 'react-scroll';
import little2 from '../../images/layerlogo.png'
import axios from 'axios';
// import ImageList from '@mui/material/ImageList';
// import ImageListItem from '@mui/material/ImageListItem';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';






const Footer = () => {
const { t } = useTranslation();
  const toggleHome = () => {
    scroll.scrollToTop();
  }





  const [socialLinks, setLinks] = useState([]);



  // const [socialLinks, setSocialLinks] = useState([]);

  const getSociallinks = () => {

    setLinks([
      {
        country: 'KE',
        name :'KENYA',

        Linkedin: "https://www.linkedin.com/company/littlesuperapp/mycompany/",
        Facebook: "https://www.facebook.com/LittleAppKE",
        Twitter: "https://twitter.com/littleappkenya",
        IG:" https://www.instagram.com/littlerideke/",
        Tiktok: "https://www.tiktok.com/@littleappkenya",

        facebook: 'https://www.facebook.com/LittleAppKE/',
        linkedIn: 'https://www.linkedin.com/company/littleride/mycompany/',
        instagram: 'https://www.instagram.com/littlerideke/',
        twiter: 'https://twitter.com/littleappkenya',
        tiktok: '',
        email: 'support@little.africa',
        phoneNumber: false,
        address: 'Little App Africa, Craft Silicon Campus, Waiyaki Way, Nairobi',
        flag: 'https://cdn.britannica.com/15/15-004-B5D6BF80/Flag-Kenya.jpg'

      },
      {
        country: 'UG',
        name : 'UGANDA',

        Linkedin:'https://www.linkedin.com/company/little-app-uganda/',
        Facebook: "https://www.facebook.com/LittleAppUG",
        Twitter: null,
        instagram: "https://www.instagram.com/littleappuganda/",

        facebook: 'https://www.facebook.com/LittleAppUG/',
        linkedIn: 'https://www.linkedin.com/company/little-app-uganda/',
        // instagram: 'https://www.instagram.com/littlerideke/',

        twiter: null,


        tiktok: '',
        email: 'ug@little.africa',
        phoneNumber: '+256740910520',
        address: 'Plot 33a Bukoto Crescent Road',
        flag: 'https://upload.wikimedia.org/wikipedia/commons/thumb/4/4e/Flag_of_Uganda.svg/1280px-Flag_of_Uganda.svg.png'
      },
      {
        country: 'TZ',
        name :'TANZANIA',

        Linkedin:" https://www.linkedin.com/company/little-app-tanzania",
        Facebook:" https://www.facebook.com/LittleAppTz",
        Twitter: null,
        IG:" https://www.instagram.com/littletanzania/",

        facebook: 'https://www.facebook.com/LittleAppTz/',
        linkedIn: 'https://www.linkedin.com/company/littleride/mycompany/',
        instagram: 'https://www.instagram.com/littletanzania/',
        twiter: null,
        tiktok: '',
        email: 'custumerservice@little.africa',
        phoneNumber: '+255 762 874 302',
        address: 'mikocheni b plot no14',
        flag: 'https://africanrelief.org/wp-content/uploads/2021/03/tanzania-flag-png-large.jpg'

      },
      {
        country: 'ET',
        NAME :'ETHIOPIA',

        Linkedin: "https://www.linkedin.com/company/little-app-ethiopia/",
        Facebook: "https://www.facebook.com/profile.php?id=100076164102612",
        Twitter:null,
        IG:" https://www.instagram.com/littleapp.et/",
        Tiktok: "https://www.tiktok.com/@little_et",

        facebook: 'https://www.facebook.com/littleappethiopia',
        linkedIn: 'https://www.linkedin.com/company/littleride/mycompany/',
        instagram: 'https://www.instagram.com/littleapp.et/',
        twiter: 'https://twitter.com/littleappeth',
        tiktok: ' https://www.facebook.com/littleappethiopia',
        email: 'info.ethiopia@little.africa',
        phoneNumber: '+25115571407',
        address: 'Bitwedded Baheru Abreham Tower, Africa Avenue, Addis Ababa',
        flag: 'https://upload.wikimedia.org/wikipedia/commons/thumb/7/71/Flag_of_Ethiopia.svg/800px-Flag_of_Ethiopia.svg.png'
      },
      {
        country: 'SO',
        NAME:'SOMALIA',

        Linkedin: "https://www.linkedin.com/company/little-app-somalia/about/",
        Facebook: "https://www.facebook.com/LittleAppSomalia",
        Twitter:" https://twitter.com/littleappsom",
        IG: "https://www.instagram.com/littleappsomalia/",
        Tiktok: "https://www.tiktok.com/@littleappsomalia",

        facebook: 'https://www.facebook.com/littleappsomalia',
        linkedIn: 'https://www.linkedin/in/littleappsomalia',
        instagram: 'https://www.instagram.com/littleappsomalia',
        twiter: 'https://www.twitter.com/littleappsom',
        tiktok: 'https://www.tiktok.com/littleappsomalia',
        email: 'littlesom@little.africa',
        phoneNumber: '3838',
        address: 'Ventuno October Road, Sultan Business Park, 3rd Floor, Mogadishu, Somalia',
        flag: 'https://upload.wikimedia.org/wikipedia/commons/thumb/a/a0/Flag_of_Somalia.svg/800px-Flag_of_Somalia.svg.png?20220419182139'

      },
      {
        country: 'SN',
        name : 'SENEGAL',

        LinkedIn:null,
        Facebook:null,
        Twitter: 'https://twitter.com/LittleWIGO',
        IG:null,
        Tiktok: 'https://www.tiktok.com/@littlewigo',

        facebook: null,
        Linkedin: 'https://sn.linkedin.com/company/littlewigo',
        instagram: null,
        twiter: 'https://twitter.com/littlewigo',
        tiktok: '',
        email: 'contact@wigo.africa',
        phoneNumber: '+221 77 744 96 96',
        address: 'Mamelles aviation  villa Nº 48 bloc B, Dakar',
        flag: 'https://upload.wikimedia.org/wikipedia/commons/thumb/f/fd/Flag_of_Senegal.svg/1280px-Flag_of_Senegal.svg.png'

      },
     
      {
        country: 'GH',
        name :'GHANA',
        
        Linkedin: "https://www.linkedin.com/company/little-appghana/",
        Facebook: "https://www.facebook.com/LittleAppGhana",
        Twitter: "https://twitter.com/LittleGhana22",
        IG: "https://www.instagram.com/little_app_ghana/",
        Tiktok: "https://www.tiktok.com/@little_ghana",
        facebook: 'https://www.facebook.com/LittleAppGhana',
        linkedIn: 'https://ke.linkedin.com/company/littlesuperapp',
        instagram: 'https://www.instagram.com/little_app_ghana/',
        twiter: 'https://twitter.com/LittleGhana22',
        tiktok: '',
        email: 'customer-support@little.bz ',
        phoneNumber: '0507795596',
        address: '14 Adebeto Close, Cantonment, Accra P.O Box CT 1003',
        flag: 'https://cdn.britannica.com/54/5054-004-A09ABCDF/Flag-Ghana.jpg'

      },
      {
        country: 'IND',
        name :'INDIA',
        Linkedin: "https://www.linkedin.com/company/100575470/admin/feed/posts/",
        // facebook: 'https://www.facebook.com/LittleAppKE/',
        // linkedIn: 'https://www.linkedin.com/company/100575470/admin/feed/posts/',
        // instagram: 'https://www.instagram.com/littlerideke/',
        // twiter: 'https://twitter.com/littleappkenya',
        tiktok: '',
        email: 'info.india@little.global',
        phoneNumber: '+91 70164 43239',
        address: 'Craft Silicon, Ahmedabad',
        flag: 'https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/1200px-Flag_of_India.svg.png'

      },
    ])




  }




  useEffect(() => {
    getSociallinks();
  }, []);


  return (
    <FooterContainer>
      <FooterWrap>

        <FooterLinkContainer to='#'>
          
          <p style={{width: '70%', color:'#d5d6d7', fontSize: '15px',lineHeight:'1.5'}}>
          <img alt="little" src={little2} height='80'/><br/><br/>
           {t('footer.about')} </p>
       

          <FooterLinkWrapper style={{padding:'10px'}}>


<FooterLinkItems>
{
socialLinks.flaglink ?  <FooterLinkTitle style={{fontWeight:'lighter',fontSize:'20px'}}>
<p> {t('footer.contact')} </p>     
<br/>
<img
src={socialLinks.flaglink}
alt=" "
height="20px"
sx={{marginLeft:'10px',marginTop:"10px",display:'inline-block'}}
/>
</FooterLinkTitle> : ' '
}
    { socialLinks.email ?  <FooterLink to="#">Email : {socialLinks.email} </FooterLink> : ''  }
    { socialLinks.mobile ?  <FooterLink to="#">Mobile Number : {socialLinks.mobile}</FooterLink> : ''  }
</FooterLinkItems>

</FooterLinkWrapper>
        </FooterLinkContainer>

        <ul style={{ display:'flex' , alignItems:'end' , flexWrap:'wrap', justifyContent:'start' , width:'100%' , marginTop:'30px', marginBottom:'10px'}}>
{
  socialLinks.map(link => (
  
  <li style={{ listStyleType : 'none' , margin:'10px'}}>
  
   { link.flag ?  <img src={link.flag} style={{ width:'35px'}} alt=" " /> : ''}

    { link.country ?  <p style={{color:'white' ,fontWeight:'bold'}}>{link.country } </p> : ''}

    { link.email ?   <p style={{color:'white'}}> Email : {link.email} </p> : ' '}

   { link.phoneNumber ?  <p style={{color:'white'}}> Mobile : {link.phoneNumber} </p> : ' '}
  
<div style={{ display:'flex' , alignItems :'center' , justifyContent:'start' , marginTop:'10px'}}>



{
  link.Linkedin ? <a href={link.Linkedin} target='_blank' style={{ textDecoration:'none' , margin:'0px 15px 0px 10px' }}>
<FaLinkedin fill="white" size={20}  />
</a> : ' ' }

{

 link.facebook ? <a href={link.facebook} target='_blank' style={{ textDecoration:'none' , margin:'0px 15px 0px 10px'}}>
<FaFacebook fill="white" size={20}  />
</a> : ' ' 
}

{
 link.twiter ? <a href={link.twiter} target='_blank' style={{ textDecoration:'none' , margin:'0px 15px 0px 10px'}}>
<FaTwitter fill="white" size={20}  />
</a> : ' ' 

}

{

 link.instagram ? <a href={link.instagram} target='_blank' style={{ textDecoration:'none' , margin:'0px 15px 0px 10px'}}>
<FaInstagram fill="white" size={20}  />
</a> : ' ' 
}

</div>

{ link ?  <hr style={{
          borderColor: 'rgba(255,255,255,.6)',
          height: '.75px',
          marginTop:'5px'
        }}
      /> : ' '}
  
    
    </li>))
}
</ul>



        <SocialMedia >

          <SocialMediaWrap>
         

            <TermsAndConditions href='https://little.bz/privacy.html' target='_blank'
            
            style={{textDecoration:'none',fontSize:'14px',  marginLeft:'10px' , marginRight:'10px'}}
            
            >{t('footer.privacy')}</TermsAndConditions>

<TermsAndConditions href='https://little.bz/data-deletion.html' target='_blank'
            
            style={{textDecoration:'none',fontSize:'14px' , marginLeft:'10px' , marginRight:'10px'}}
            
            >{t('footer.dataDeletion')}</TermsAndConditions>

<TermsAndConditions href='https://little.africa/anti_discrimination.pdf' target='_blank'
            
            style={{textDecoration:'none',fontSize:'14px'  , marginLeft:'10px' , marginRight:'10px'}}
            
            >{t('footer.equalityPolicy')}</TermsAndConditions>
    
            <TermsAndConditions href='https://little.africa/tnc.php' target='_blank'
                style={{textDecoration:'none',fontWeight:'lighter',fontSize:'14px'}}
            >
              
             {t('footer.terms')}
              
              </TermsAndConditions>

           
            <WebsiteRights> {new Date().getFullYear()} {t('footer.copyright')}</WebsiteRights>

            <SocialIcons>


           
              {/* <SocialIconLink >
                <a href={socialLinks.facebook} target='_blank' sx={{
                  textDecoration:'none'
                }}>
                <FaFacebook fill="white" />
                </a>
              </SocialIconLink>


              <SocialIconLink>

              <a href={socialLinks.instagram} target='_blank' sx={{
                  textDecoration:'none'
                }}>
              <FaInstagram fill="white" />
                </a>
              </SocialIconLink>



              <SocialIconLink>

<a href={socialLinks.twitter} target='_blank' sx={{
    textDecoration:'none'
  }}>
<FaTwitter sx={{color:'white',backgroundColor:'white'}} fill="white" />
  </a>
</SocialIconLink>


<SocialIconLink>

<a href={socialLinks.linkedIn} target='_blank' sx={{
    textDecoration:'none'
  }}>
<FaLinkedin fill="white" />
  </a>
</SocialIconLink> */}



              

            {/* <div class="dropup">
            <button class="dropbtn">
            <SocialIconLink>
                <FaInstagram />
              </SocialIconLink>
              </button>
              <div class="dropup-content">
                <a href="https://www.instagram.com/littlerideke/" target="_blank" rel="noopener noreferrer">Kenya</a>
                <a href="https://www.instagram.com/littlerideke/" target='_blank'>Uganda</a>
                <a href="https://www.instagram.com/littlerideke/" target='_blank'>Tanzania</a>
                <a href="https://www.instagram.com/littlerideke/" target='_blank'>Ethiopia</a>
                <a href="https://www.instagram.com/littlerideke/" target='_blank'>Senegal</a>
                
    <a href="https://www.instagram.com/little_app_ghana/" target='_blank'>Ghana</a>
              </div>
            </div> */}
              
            {/* <div class="dropup">
            <button class="dropbtn">
            <SocialIconLink>
                <FaTwitter />
              </SocialIconLink>
              </button>
              <div class="dropup-content">
                <a href="https://twitter.com/littleappkenya" target="_blank" rel="noopener noreferrer">Kenya</a>
                <a href="https://twitter.com/littleappkenya" target='_blank'>Uganda</a>
                <a href="https://twitter.com/littleappkenya" target='_blank'>Tanzania</a>
                <a href="https://twitter.com/littleappkenya" target='_blank'>Ethiopia</a>
                <a href="https://twitter.com/littleappkenya" target='_blank'>Senegal</a>
                
  <a href="https://twitter.com/LittleGhana22" target='_blank'>Ghana</a>
              </div>
            </div> */}

            {/* <div class="dropup">
            <button class="dropbtn">
            <SocialIconLink>
                <FaLinkedin />
              </SocialIconLink>
              </button>
              <div class="dropup-content">
                <a href="https://www.linkedin.com/company/littleride/mycompany/" target="_blank" rel="noopener noreferrer">Kenya</a>
                <a href="https://www.linkedin.com/company/littleride/mycompany/" target='_blank'>Uganda</a>
                <a href="https://www.linkedin.com/company/littleride/mycompany/" target='_blank'>Tanzania</a>
                <a href="https://www.linkedin.com/company/littleride/mycompany/" target='_blank'>Ethiopia</a>
                <a href="https://www.linkedin.com/company/littleride/mycompany/" target='_blank'>Senegal</a>
      <a href="
      https://www.linkedin.com/company/little-appghana/" target='_blank'>Ghana</a>
              </div>
            </div> */}
            
            </SocialIcons>

          </SocialMediaWrap>
        </SocialMedia>


        
      </FooterWrap>
    </FooterContainer>
  )
}

export default Footer
