import React from 'react'
import { Button } from '../ButtonElements'
import { InfoContainer, InfoWrapper, InfoRow, Column1, Column2, TextWrapper, TopLine, Heading, Subtitle, BtnWrap, ImgWrap, Img } from './InfoElements'
import rideImage from '../../images/Ride1.png'
import stars from '../../images/stars.PNG'
import background from "../../images/Deliveries1.png"
import { useTranslation } from 'react-i18next';

const Deliveries = ({lightBg, id, imgStart, topLine, lightText, headline, darkText, description, buttonLabel, alt, img, primary, dark, dark2 }) => {
  const { t } = useTranslation();
  return (
    <>
      <InfoContainer lightBg={false} id='delivery'>
        <InfoWrapper>
          <InfoRow imgStart={false}>
            <Column1>
              <TextWrapper>
                
      <Heading lightText={false}>{t('deliveries.heading')}</Heading>
      <TopLine><img alt="little" src={stars}/></TopLine>
      <Subtitle darkText={true}>{t('deliveries.description1')}</Subtitle>
      <Subtitle darkText={true}>{t('deliveries.description2')}</Subtitle>
      <Subtitle darkText={true}>{t('deliveries.description3')}</Subtitle>
              </TextWrapper>
              
            </Column1>
            <Column2>
              <ImgWrap>
                <Img src={background} alt={alt}/>
              </ImgWrap>
            </Column2>
          </InfoRow>
        </InfoWrapper>
      </InfoContainer>
    </>
  )
}

export default Deliveries
