import React from 'react'
import { Button } from '../ButtonElements'
import { InfoContainer, InfoWrapper, InfoRow, Column1, Column2, TextWrapper, TopLine, Heading, Subtitle, BtnWrap, ImgWrap, Img } from './InfoElements'
import rideImage from '../../images/Ride1.png'
import background from "../../images/Ride1.png"
import { useTranslation } from 'react-i18next';

const Ride = ({ lightBg, id, imgStart, topLine, lightText, headline, darkText, description, buttonLabel, alt, img, primary, dark, dark2 }) => {
  const { t } = useTranslation();
  return (
    <>
      <InfoContainer lightBg={false} id='rise'>
        <InfoWrapper>
          <InfoRow imgStart={false}>
            <Column1>
              <TextWrapper>
      <Heading lightText={false}>{t('ride.heading')}</Heading>
      <TopLine>{t('ride.topline')}</TopLine>
      <Subtitle darkText={true}>{t('ride.description')}</Subtitle>
      <BtnWrap>
        <Button 
          to='home'
          smooth={true}
          duration={500}
          onClick={()=> window.open("https://little.africa/littlerider", "_blank")}
          spy={true}
          exact='true'
          offset={-80}
          primary={primary ? 1 : 0}
          dark={dark ? 1 : 0}
          dark2={dark2 ? 1 : 0}
        >
          {t('ride.button')}
        </Button>
      </BtnWrap>
              </TextWrapper>
            </Column1>
            <Column2>
              <ImgWrap>
                <Img src={rideImage} alt={alt}/>
              </ImgWrap>
            </Column2>
          </InfoRow>
        </InfoWrapper>
      </InfoContainer>
    </>
  )
}

export default Ride
