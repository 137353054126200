import React from 'react'
import { useTranslation } from 'react-i18next'
import {InfoSec, InfoRow, InfoColumn, TextWrapper, TopLine, Heading, Subtitle, PhoneContainer, Img, PhoneFrame, SmartPhone, SmartPhoneVideo} from './InfoSection.elements'
import { Container, Button } from '../../globalStyles'
import smartphone from '../../images/phone.webp'
import myVideo from '../../images/little_small.mp4'
import myVideoweb from '../../images/little_small.webp'
import myVideogif from '../../images/little_small.gif'


// import { Link } from 'react-router-dom'
    const TopSlider = ({ primary, lightBg, ...props }) => {
    const { t } = useTranslation();

    return (
      <>
        <InfoSec lightBg={lightBg} id='home'>
          <Container>
            <InfoRow imgStart={false}>
              <InfoColumn>
                <TextWrapper>
                  <span style={{color: '#353232', width: '50%', lineHeight: '1.3', fontWeight: '700'}}>
                    <span style={{color: '#EDB21F'}}>{t('hero.tagline.your')}</span><br/>
                    <span style={{color: '#00ba75'}}>{t('hero.tagline.every1')}</span>
                    <span style={{color: '#008ad0'}}>{t('hero.tagline.day')}</span>.
                    <span style={{color: '#FF0000'}}>{t('hero.tagline.every2')}</span>
                    <span style={{color: '#FF0000'}}>{t('hero.tagline.thing')}</span><br/>
                    {t('hero.tagline.app')}<br/><br/>
                  </span>
                  <span style={{width: '100%', color: '#595959', fontSize: '20px', fontWeight: '500'}}>
                    {t('hero.description')}
                  </span><br/><br/>
                  <Button big fontBig primary={primary} onClick={()=> window.open("https://little.africa/littlerider", "_blank")}>
                    {t('hero.cta')}
                  </Button>
                </TextWrapper>
              </InfoColumn>
              <InfoColumn>
                <PhoneContainer>
                  <PhoneFrame>
                    <SmartPhone src={smartphone}>
                    </SmartPhone>
                    <SmartPhoneVideo width='200px' height='200px' autoPlay muted loop>
                      <source src={myVideo}/>
                      <source src={myVideoweb} onerror="fallback(parentNode)"/>
                      <img src={myVideogif} alt="Fallback GIF"></img>
                    </SmartPhoneVideo>
                  </PhoneFrame>
                </PhoneContainer>
              </InfoColumn>
            </InfoRow>
          </Container>
        </InfoSec>
      </>
    )
}
export default TopSlider;