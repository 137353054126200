
import React from 'react'
import { Button } from '../ButtonElements'
import { InfoContainer, InfoWrapper, InfoRow, Column1, Column2, TextWrapper, TopLine, Heading, Subtitle, BtnWrap, ImgWrap, Img, Card } from './InfoElements'
import rideImage from '../../images/Ride1.png'
import zigzag from '../../images/zig zag.png'
import Carousel from './components/Carousel';
import axios from "axios";
import Slider from "react-slick";


const baseURL = "https://www.little.bz/blog/wp-json/wp/v2/posts";



const Blogs = ({lightBg, id, imgStart, topLine, lightText, headline, darkText, description, buttonLabel, alt, img, primary, dark, dark2 }) => {

  //call PI 
  const [post, setPost] = React.useState([]);
  let theQuerry =  {        
    per_page: 5, // Only retrieve the 3 most recent blog posts.
    page: 1, // Current page of the collection.
    _embed: true, //Response should include embedded resources.
     }
  React.useEffect(() => {

    axios
        .get(baseURL , { params: theQuerry})
        .then(response => {
          // let res = JSON.stringify(response.data)
          // console.log("blogs today image" , response.data[0]._embedded['wp:featuredmedia'][0].source_url)
          // console.log("blogs today exerpt" , response.data[0].title.rendered)
          // console.log("blogs today description" , response.data[0].excerpt.rendered)
          // console.log("blogs today link" , response.data[0].slug)
          setPost(response.data);

        })
        .catch(error => {
            console.log(error);
        });
    axios.get(baseURL).then((response) => {
      // setPost(response.data);
      console.log("blogs today" + response)
    });
  }, []);

  var settings = {
    dots: true,
  infinite: false,
  speed: 300,
  slidesToShow: 3,
  slidesToScroll: 4,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true,
        dots: true
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
    // You can unslick at a given breakpoint now by adding:
    // settings: "unslick"
    // instead of a settings object
  ]
  };

  const config = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: '50px',
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ]
  };

  const [settingss, setSettings] = React.useState(config);

  const products = [
    {
      img: 'https://www.little.bz/blog/wp-content/uploads/2022/01/Enterprise_Preset-1200x675.jpg',
      title: 'Little launches ride-hailing operations in Malindi',
      text: 'Lorem ipsum dolor sit amet elit.'
    },
    {
      img: 'https://www.little.bz/blog/wp-content/uploads/2022/01/Vacant-position-Sales-Executive-1200x675.jpg',
      title: 'Eget est lorem',
      text: 'Lorem Ipsum adipiscing elit ipsum.'
    },
    {
      img: 'https://www.little.bz/blog/wp-content/uploads/2021/11/MicrosoftTeams-image-1024x373.jpg',
      title: 'Tempus imperdiet',
      text: 'Orci porta non pulvinar neque laoreet.'
    },
    {
      img: 'https://www.little.bz/blog/wp-content/uploads/2021/11/MicrosoftTeams-image-1024x373.jpg',
      title: 'Tempus imperdiet',
      text: 'Orci porta non pulvinar neque laoreet.'
    }
  ]

  const onChangeCenterMode = e => {
    if (e.target.checked) {
      setSettings({
        ...config,
        centerMode: true,
        centerPadding: '50px'
      });
    } else {
      setSettings(config);
    }
  }
  return (
    <>
    
    <div className="App" >
      {/* <h3>Carousel Slider in React - <a href="https://www.cluemediator.com" target="_blank">Clue Mediator</a></h3>
      <label className="cb-centermode">
        <input type="checkbox" checked={settings.centerMode} onChange={onChangeCenterMode} />
        <span>Enable Center M
        ode</span>onClick={() => alert("i am "+i)}
      </label> */}

     <InfoRow imgStart={false}>
          <img alt="little" src={zigzag}/>
        
              <TextWrapper>
             
                <Heading lightText={false} >Blogs</Heading>
                
                <span>
                  
                </span>
              </TextWrapper>
            
          </InfoRow>
          <div style={{width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
          <Slider {...config} style={{width: '90%'}}>
        {post.map((x, i) => {
          return <div key={i} className="img-card" onClick={() => window.open(
            `https://little.bz/blog/`+ x.slug,
            '_blank' // <- This is what makes it open in a new window.
          )}>
            <img className="img" src={x._embedded['wp:featuredmedia'] ? x._embedded['wp:featuredmedia'][0].source_url : 'https://thethinkingbrand.files.wordpress.com/2016/10/14355107_702030923279417_559273380529676907_n-1.jpg?w=960'} />
            <div class="card-body">
              <div className="card-title" style={{fontSize: '16px'}}>{x.title.rendered}</div>
              {/* <div className="card-text">{x.text}</div> */}
            </div>
          </div>
        })}
      </Slider>
          </div>
     
    </div>
      
    </>
  )
}

export default Blogs;