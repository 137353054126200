import React from 'react'
import { InfoContainer, InfoWrapper, InfoRow, Column1, Column2, TextWrapper, Subtitle } from './InfoElements'
import zigzag from '../../images/zig zag.png'
import YoutubeEmbed from "./youtube";
import { useTranslation } from 'react-i18next';

const Info = ({lightBg, id, imgStart, topLine, lightText, headline, darkText, description, buttonLabel, alt, img, primary, dark, dark2 }) => {
  const { t } = useTranslation();

  // Rest of your component code

  return (
    <>
      <InfoContainer lightBg={lightBg} id='mobility'>
        <InfoWrapper style={{backgroundColor: '#f0f0fe'}}>
          <InfoRow imgStart={imgStart}>
            <Column1>
              <TextWrapper>
                <span style={{color: '#000000'}}>
                  <span style={{fontWeight: '700'}}>{t('mobility.brand')}</span> - {t('mobility.initiative')} <br/>
                  {t('mobility.support')}
                </span><br/>
                <img alt="little" src={zigzag} style={{marginTop: '16px', marginBottom: '16px'}}/>
                <Subtitle darkText={darkText}>
                  {t('mobility.description')}
                </Subtitle>
              </TextWrapper>
            </Column1>
            <Column2>
            {/* <div style={{width:"500px", height: "300px", backgroundColor: '#f0f0f0'}}> */}
                <YoutubeEmbed embedId="5UmZQMlf9Tw" />           
                 {/* </div> */}
            {/* <ReactPlayer width='600px' height= "400px" url='https://www.youtube.com/watch?v=5UmZQMlf9Tw' /> */}
            {/* <ModalVideo channel='youtube' autoplay isOpen={isOpen} videoId="5UmZQMlf9Tw" onClose={() => setOpen(false)} />

            <button className="btn-primary" onClick={()=> setOpen(true)}>VIEW video</button> */}

            
            </Column2>
          </InfoRow>
        </InfoWrapper>
        
      </InfoContainer>
    </>
  )
}

export default Info
